import Router from "next/router";
import React, { Component } from "react";

import { PAGE_ROUTES } from "../constants/Navigation";

class Index extends Component {
  componentDidMount = () => {
    // if we successfully pass auth then take the user
    // directly to their dashboard.
    Router.push({
      pathname: PAGE_ROUTES.LANDING,
      query: Router.query,
    });
  };

  render() {
    return <div />;
  }
}

export default Index;
