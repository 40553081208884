/**
 * Absolute paths to all pages
 */
export const PAGE_ROUTES = {
  ONBOARDING_BASE: "/app/onboarding",
  ONBOARDING_SUBSCRIPTION: "/app/onboarding/subscription",

  DASHBOARD: "/app/dashboard",
  MEMORIES: "/app/memories",
  CHILDREN: "/app/children",
  SETTINGS: "/app/settings",
  SEARCH: "/app/search",
  NOTIFICATION_SETTINGS: "/app/settings/notifications",

  LANDING: "/landing",

  TERMS_OF_SERVICE: "/tos",
  PRIVACY_POLICY: "/privacy",
};
